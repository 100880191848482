import { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { isEmailValido } from "../utiles/validaciones";
import { getGeneralApi } from "../api/general";
import { postGeneralApi } from "../api/general";

export const Configuracion = () => {
    const [datosFormulario, setDatosFormulario] = useState({});
    const [notificacionesChecked, setNotificacionesChecked] = useState(false);
    const toast = useRef();

    useEffect(() => {
        getGeneralApi("getDatosPropiosGestor")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    const datosGestor = respuesta.data.items[0];
                    setDatosFormulario(datosGestor);
                    setNotificacionesChecked(datosGestor.notificaciones); //Hack para primeReact Checkbox
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            notificaciones: notificacionesChecked, //Hack para primeReact Checkbox
            emailNotificaciones: e.target.emailNotificaciones.value,
            cargo: e.target.cargo.value,
            comentarioGestor: e.target.comentarioGestor.value,
        };

        if (!isEmailValido(datosFormularioEnvio.emailNotificaciones)) {
            toast.current.show({ severity: "warn", summary: "Datos no válidos", detail: "No es una dirección de email válida" });
            return;
        }

        postGeneralApi("updateDatosPropiosGestor", datosFormularioEnvio)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Datos guardados" });
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error guardando datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h5>Datos de Configuración</h5>
                        <form onSubmit={onSubmit}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div style={{ margin: "20px 0" }} className="p-field-checkbox p-col-12">
                                    <Checkbox inputId="notificaciones" name="notificaciones" defaultValue={datosFormulario.notificaciones} checked={notificacionesChecked} onChange={(evento) => setNotificacionesChecked(evento.checked)} />
                                    <label htmlFor="notificaciones">{notificacionesChecked ? "Desactivar notificaciones" : "Activar notificaciones"}</label>
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="emailNotificaciones">Email Notificaciones</label>
                                    <InputText name="emailNotificaciones" type="text" defaultValue={datosFormulario.emailNotificaciones} />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="cargo">Cargo</label>
                                    <InputText name="cargo" type="text" defaultValue={datosFormulario.cargo} />
                                </div>
                                <div className="p-field p-col-12">
                                    <label htmlFor="comentarioGestor">Informacion general</label>
                                    <InputTextarea name="comentarioGestor" rows="4" defaultValue={datosFormulario.comentarioGestor} />
                                </div>
                                <div style={{ padding: "16px 0 0 16px" }} className="p-field">
                                    <Button label="ACTUALIZAR" type="submit"></Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
