import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { postGeneralApi } from "../api/general";

export function AvisoNuevo(props) {
    const { callBack, pedidoId } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const [activoChecked, setActivo] = useState(true);
    const labelGeneral = "Crear Aviso";

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            activo: activoChecked, //Hack para primeReact Checkbox
            aviso: e.target.aviso.value,
        };
        postGeneralApi("updateDatosPedidoCreaAviso", datosFormularioEnvio, pedidoId)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Datos guardados" });
                    overlayPanel.current.hide();
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error guardando datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-outlined" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <div className="p-field-checkbox">
                            <Checkbox inputId="activo" name="activo" checked={activoChecked} onChange={(evento) => setActivo(evento.checked)} />
                            <label htmlFor="activo">{activoChecked ? "Pendiente" : "Resuelto"}</label>
                        </div>
                        <div className="p-field">
                            <label htmlFor="aviso">Descripción</label>
                            <br />
                            <InputTextarea cols="40" name="aviso" rows="4" />
                        </div>
                        <div className="p-field">
                            <Button label="CREAR" type="submit"></Button>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
