import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { postGeneralApi } from "../api/general";
import { estadosPedidoMasTodos } from "../api/varios";

import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router";
import { EstadoTexto } from "../componentes/EstadoTexto";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import Moment from "react-moment";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";
import { ClasificacionTexto } from "../componentes/ClasificacionTexto";
import { BotonIdTexto } from "../componentes/BotonIdTexto";

export const Pedidos = () => {
    const [pedidos, setPedidos] = useState(null);
    const [selectedCustomers1, setSelectedCustomers1] = useState(null);
    const [globalFilter, setGlobalFilter] = useState("");
    const [loading, setLoading] = useState(true);
    const toast = useRef();
    const history = useHistory();
    const [datosDptos, setDatosDptos] = useState({});
    const [dptoDrop, setDptoDrop] = useState(0);
    const [datosGestores, setDatosGestores] = useState({});
    const [datosUsuarios, setDatosUsuarios] = useState({});
    const [gestorDrop, setGestorDrop] = useState(0);
    const [usuarioDrop, setUsuarioDrop] = useState(0);
    const [estadoDrop, setEstadoDrop] = useState("TODOS");
    //const [fecha1, setfecha1] = useState(moment().subtract(12, "months").toDate());
    //const [fecha2, setfecha2] = useState(moment().toDate());
    const [fecha1, setfecha1] = useState(newDate());
    const [fecha2, setfecha2] = useState(new Date());
    const [reload, setReload] = useState(Date.now());

    addLocale("es", CALENDAR_LOCALE_ES);

    function newDate() {
        var d = new Date();
        d.setMonth(d.getMonth() - 3);
        return d;
    }

    async function getListaDptos() {
        await getGeneralApi("listaDptosMasTodos")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosDptos(respuesta.data.items);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de departamentos" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    async function getListaGestores() {
        await getGeneralApi("listaGestoresMasTodos")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosGestores(respuesta.data.items);
                    //console.log("/>> " + JSON.stringify(respuesta.data.items));
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de gestores" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    async function getListaUsuarios() {
        await getGeneralApi("listaUsuariosActivosMasTodos")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosUsuarios(respuesta.data.items);
                    //console.log("/>> " + JSON.stringify(respuesta.data.items));
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de usuarios" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    useEffect(() => {
        async function getListaPedidos() {
            //console.log("offset: ", new Date().getTimezoneOffset());

            //var kk = getFechaInicioDia(fecha1);

            console.log("fecha1: ", fecha1);
            console.log("fecha2: ", fecha2);
            //console.log("kk1: ", kk);
            //console.log("kk2: ", kk.toDate());

            //var fecha1DesdeInicioDia = getFechaInicioDia(fecha1); //moment(fecha1).utcOffset(0);
            // fecha1DesdeInicioDia.set({hour: 0,minute: 0,second: 0,millisecond: 0 });

            //var fecha2HastaFinDia = getFechaAjusteUTC(fecha2); //moment(fecha2).utcOffset(0);
            //fecha2HastaFinDia.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

            //console.log(">>> fecha1DesdeInicioDia: " + moment(fecha1DesdeInicioDia).format());

            const datosFormularioEnvio = {
                dpto: dptoDrop, //Hack para primeReact Dropdown
                gestor: gestorDrop, //Hack para primeReact Dropdown
                usuario: usuarioDrop, //Hack para primeReact Dropdown
                estado: estadoDrop, //Hack para primeReact Dropdown
                fecha1: fecha1,
                fecha2: fecha2,
            };

            console.log("DatosForm: ", JSON.stringify(datosFormularioEnvio));

            setLoading(true);
            await postGeneralApi("listaPedidos", datosFormularioEnvio)
                .then((respuesta) => {
                    if (respuesta?.data?.success) {
                        setPedidos(respuesta.data.items);
                        setLoading(false);
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de pedidos" });
                    }
                })
                .catch((response) => {
                    toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
                });
        }
        getListaDptos();
        getListaGestores();
        getListaUsuarios();
        getListaPedidos();
    }, [dptoDrop, gestorDrop, usuarioDrop, estadoDrop, fecha1, fecha2, reload]);

    const onSubmit = (e) => {
        e.preventDefault();
        setReload(Date.now());
    };

    const customer1TableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar en el listado" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const numIncidenciasTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data.incidencias.length > 0 && data.incidencias.length}
            </>
        );
    };

    const fechaBody = (data, props) => {
        return <>{data[props.field] ? <Moment format="DD/MM/YYYY">{data[props.field]}</Moment> : "-"}</>;
    };

    const statusBodyTemplate = (data) => {
        return <EstadoTexto estado={data?.estado} />;
    };

    const botonIdTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Número</span>
                <BotonIdTexto icono="id-card" ruta="/pedido/">
                    {data.id}
                </BotonIdTexto>
            </>
        );
    };

    const dptoTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <i className="pi pi-users"></i>
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }} className="image-text">
                    {data.dpto.nombre}
                </span>
            </>
        );
    };

    const gestorTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <i className="pi pi-user"></i>
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }} className="image-text">
                    {data.gestorComercial.nombre} {data.gestorComercial.apellidos}
                </span>
            </>
        );
    };

    const flujoTemplate = (data) => {
        return (
            <>
                <i className="pi pi-fw pi-sitemap"></i>
                <span style={{ marginLeft: ".5em", verticalAlign: "left" }} className="image-text">
                    {data.flujo.nombre}
                </span>
            </>
        );
    };

    const tipologiaTemplate = (data) => <strong>{data.tipologia.nombre}</strong>;

    const clasificacionTemplate = (data) => <span>{data?.clasificacion !== "undefined" && <ClasificacionTexto id={data?.clasificacion} />}</span>;

    const pptoBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Presup.</span>
                <BotonIdTexto icono="ticket" ruta="/presupuesto/">
                    {data.ppto.id}
                </BotonIdTexto>
            </>
        );
    };

    const usuarioTemplate = (data) => (
        <Button
            icon="pi pi-user"
            label={data.ppto.usuario.nombre}
            iconPos="left"
            type="button"
            className="p-button-secondary"
            onClick={(e) => {
                history.push("/cliente/" + data.ppto.usuario.id);
            }}
        ></Button>
    );

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <h5>Listado de Pedidos</h5>
                        <form onSubmit={onSubmit}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-2">
                                    <label htmlFor="dpto">Departamento </label>
                                    <Dropdown id="dpto" name="dpto" optionValue="id" optionLabel="nombre" value={dptoDrop} options={Array.from(datosDptos)} onChange={(e) => setDptoDrop(e.value)} placeholder="Seleccionar Departamento" />
                                </div>
                                <div className="p-field p-col-1">
                                    <label htmlFor="gestor">Gestor </label>
                                    <Dropdown id="gestor" name="gestor" optionValue="id" optionLabel="nombre" value={gestorDrop} options={Array.from(datosGestores)} onChange={(e) => setGestorDrop(e.value)} placeholder="Seleccionar Gestor" />
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="gestor">Cliente </label>
                                    <Dropdown id="usuario" name="usuario" optionValue="id" optionLabel="nombre" value={usuarioDrop} options={Array.from(datosUsuarios)} onChange={(e) => setUsuarioDrop(e.value)} placeholder="Seleccionar Usuario" />
                                </div>
                                <div className="p-field p-col-1">
                                    <label htmlFor="estado">Estado </label>
                                    <Dropdown name="estado" value={estadoDrop} options={Array.from(estadosPedidoMasTodos)} onChange={(e) => setEstadoDrop(e.value)} placeholder="Seleccionar Estado" />
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="desde">Desde</label>
                                    <Calendar
                                        locale="es"
                                        dateFormat="dd/mm/yy"
                                        id="desde"
                                        showIcon
                                        value={fecha1}
                                        onChange={(e) => {
                                            setfecha1(e.value);
                                            console.log("FECHA1[" + e.value + "]");
                                        }}
                                    ></Calendar>
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="hasta">Hasta</label>
                                    <Calendar
                                        locale="es"
                                        dateFormat="dd/mm/yy"
                                        id="hasta"
                                        showIcon
                                        value={fecha2}
                                        onChange={(e) => {
                                            setfecha2(e.value);
                                            console.log("FECHA2[" + e.value + "]");
                                        }}
                                    ></Calendar>
                                </div>
                                <div className="p-field p-col-1">
                                    <label htmlFor="buscar" style={{ visibility: "hidden" }}>
                                        Buscar
                                    </label>
                                    <Button icon="pi pi-replay" label="Recargar" type="submit"></Button>
                                </div>
                            </div>
                        </form>
                        <DataTable
                            value={pedidos}
                            paginator
                            className="p-datatable-customers p-datatable-striped p-datatable-sm"
                            rows={20}
                            dataKey="id"
                            rowHover
                            selection={selectedCustomers1}
                            onSelectionChange={(e) => setSelectedCustomers1(e.value)}
                            globalFilter={globalFilter}
                            emptyMessage="No se han encontrado pedidos."
                            loading={loading}
                            header={customer1TableHeader}
                        >
                            <Column headerStyle={{ width: "8rem", textAlign: "center" }} field="id" header="Número" sortable bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={botonIdTemplate}></Column>
                            <Column field="ref" header="Ref" sortable body={bodyTemplate}></Column>
                            <Column headerStyle={{ width: "9rem" }} field="clasificacion.nombre" header="Clasif." sortable body={clasificacionTemplate}></Column>
                            <Column headerStyle={{ width: "5rem" }} field="tipologia.nombre" header="Tip." sortable body={tipologiaTemplate}></Column>
                            <Column headerStyle={{ width: "7rem" }} field="fecha" header="Fecha" sortable body={fechaBody}></Column>
                            <Column headerStyle={{ width: "7rem" }} field="fechaEstimada" header="Estimada" sortable body={fechaBody}></Column>
                            <Column headerStyle={{ width: "10rem" }} field="estado" header="Estado" sortable body={statusBodyTemplate}></Column>
                            <Column headerStyle={{ width: "10rem" }} field="flujo.ref" header="flujo" sortable body={flujoTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="dpto.nombre" header="Dpto" sortable body={dptoTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="gestorComercial.nombre" header="Gestor Com." sortable body={gestorTemplate}></Column>
                            <Column headerStyle={{ width: "8rem", textAlign: "center" }} field="id" header="Presup." sortable bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={pptoBodyTemplate}></Column>
                            <Column headerStyle={{ width: "6rem" }} field="incidencias" header="Inc." sortable body={numIncidenciasTemplate}></Column>
                            <Column headerStyle={{ width: "14rem" }} field="usuario" header="Cliente" sortable body={usuarioTemplate}></Column>
                        </DataTable>
                        <div style={{ textAlign: "right" }}>
                            Total de pedidos obtenidos: <strong>{pedidos?.length}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
