import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Route, useHistory } from "react-router-dom";

import AppTopBar from "./plantilla/AppTopbar";

import AppFooter from "./plantilla/AppFooter";
import AppMenu from "./plantilla/AppMenu";
import AppSearch from "./plantilla/AppSearch";

import { Inicio } from "./paginas/Inicio";
import { Gestores } from "./paginas/Gestores";
import { Gestor } from "./paginas/Gestor";
import { Expediciones } from "./paginas/Expediciones";
import { Puertas } from "./paginas/Puertas";
import { Clientes } from "./paginas/Clientes";
import { GestorDatos } from "./paginas/GestorDatos";
import { ClienteDatos } from "./paginas/ClienteDatos";
import { Pedidos } from "./paginas/Pedidos";
import { PedidoDatos } from "./paginas/PedidoDatos";
import { Pptos } from "./paginas/Presupuestos";
import { PptoDatos } from "./paginas/PptoDatos";
import { Configuracion } from "./paginas/Configuracion";
import { Flujo } from "./paginas/Flujo";
import { Dptos } from "./paginas/Dptos";
import { Tipologias } from "./paginas/Tipologias";

import { getUsernameApi, getTokenApi } from "./api/autenticacion";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./plantilla/App.scss";

const App = () => {
    const [menuActive, setMenuActive] = useState(false);
    const menuMode = "static";
    const colorScheme = "light";
    const menuTheme = "layout-sidebar-darkgray";
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const inputStyle = "filled"; //"outlined", "filled"
    const ripple = false;
    const logoUrl = "assets/layout/images/logo-dark.svg";

    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let configClick = false;

    const menu = [
        {
            label: "Inicio",
            icon: "pi pi-fw pi-home",
            items: [{ label: "Estadística", icon: "pi pi-fw pi-home", to: "/" }],
        },
        { separator: true },
        {
            label: "Listados",
            icon: "pi pi-fw pi-id-card",
            items: [
                { label: "Pedidos", icon: "pi pi-fw pi-id-card", to: "/pedidos" },
                { label: "Presupuestos", icon: "pi pi-fw pi-ticket", to: "/presupuestos" },
                { label: "Clientes", icon: "pi pi-fw pi-user", to: "/clientes" },
                { label: "Gestores", icon: "pi pi-fw pi-user-plus", to: "/gestores" },
                { label: "Expediciones", icon: "pi pi-fw pi-box", to: "/expediciones" },
                { label: "Puertas", icon: "pi pi-fw pi-building", to: "/puertas" },
            ],
        },
        { separator: true },
        {
            label: "Otros",
            icon: "pi pi-fw pi-download",
            items: [
                { label: "Configuración", icon: "pi pi-fw pi-info-circle", to: "/config" },
                {
                    label: "Más info",
                    icon: "pi pi-fw pi-align-left",
                    items: [
                        { label: "Flujo de Trabajo", icon: "pi pi-fw pi-sitemap", to: "/flujo" },
                        { label: "Departamentos", icon: "pi pi-fw pi-users", to: "/dptos" },
                        { label: "Tipologías", icon: "pi pi-fw pi-directions", to: "/tipologias" },
                    ],
                },
            ],
        },
    ];

    const routers = [
        { path: "/", component: Inicio, exact: true, meta: { breadcrumb: [{ parent: "Inicio", label: "Estadísticasss" }] } },
        { path: "/pedidos", component: Pedidos, meta: { breadcrumb: [{ parent: "Listados", label: "Pedidos" }] } },
        { path: "/pedido/:id", component: PedidoDatos, meta: { breadcrumb: [{ parent: "Listados", label: "Datos Pedido" }] } },
        { path: "/presupuestos", component: Pptos, meta: { breadcrumb: [{ parent: "Listados", label: "Presupuestos" }] } },
        { path: "/presupuesto/:id", component: PptoDatos, meta: { breadcrumb: [{ parent: "Listados", label: "Datos Presupuesto" }] } },
        { path: "/clientes", component: Clientes, meta: { breadcrumb: [{ parent: "Listados", label: "Clientes" }] } },
        { path: "/cliente/:id", component: ClienteDatos, meta: { breadcrumb: [{ parent: "Listados", label: "Datos Cliente" }] } },
        { path: "/gestores", component: Gestores, meta: { breadcrumb: [{ parent: "Listados", label: "Gestores" }] } },
        { path: "/gestor/:id", component: Gestor, meta: { breadcrumb: [{ parent: "Listados", label: "Gestor" }] } },
        { path: "/expediciones", component: Expediciones, meta: { breadcrumb: [{ parent: "Listados", label: "Expediciones" }] } },
        { path: "/puertas", component: Puertas, meta: { breadcrumb: [{ parent: "Listados", label: "Puertas" }] } },
        { path: "/config", component: Configuracion, meta: { breadcrumb: [{ parent: "Otros", label: "Configuración" }] } },
        { path: "/datosGestor", component: () => <GestorDatos logoUrl={logoUrl} />, meta: { breadcrumb: [{ parent: "Otros", label: "Datos Gestor" }] } },
        { path: "/flujo", component: Flujo, meta: { breadcrumb: [{ parent: "Otros", label: "Flujo de Trabajo" }] } },
        { path: "/dptos", component: Dptos, meta: { breadcrumb: [{ parent: "Otros", label: "Departamentos" }] } },
        { path: "/tipologias", component: Tipologias, meta: { breadcrumb: [{ parent: "Otros", label: "Tipologías" }] } },
    ];

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        changeStyleSheetUrl("layout-css", "layout-" + colorScheme + ".css", 1);
        changeStyleSheetUrl("theme-css", "theme-" + colorScheme + ".css", 1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute("href").split("/");

        if (from === 1) {
            // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {
            // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join("/");

        replaceLink(element, newURL);
    };

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute("href", href);
        } else {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute("href", href);
            cloneLinkElement.setAttribute("id", id + "-clone");

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove();
                cloneLinkElement.setAttribute("id", id);
            });
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!menuClick) {
            if (isSlim()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        notificationMenuClick = false;
        menuClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);

        if (isOverlay()) {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
        } else {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive((prevTopbarUserMenuActive) => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive((prevTopbarNotificationMenuActive) => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive((prevSearchActive) => !prevSearchActive);
        searchClick = true;
    };

    const onSearchClick = () => {
        searchClick = true;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add("blocked-scroll");
        } else {
            document.body.className += " blocked-scroll";
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove("blocked-scroll");
        } else {
            document.body.className = document.body.className.replace(new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"), " ");
        }
    };

    const isSlim = () => {
        return menuMode === "slim";
    };

    const isOverlay = () => {
        return menuMode === "overlay";
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const containerClassName = classNames(
        "layout-wrapper",
        {
            "layout-overlay": menuMode === "overlay",
            "layout-static": menuMode === "static",
            "layout-slim": menuMode === "slim",
            "layout-sidebar-dim": colorScheme === "dim",
            "layout-sidebar-dark": colorScheme === "dark",
            "layout-overlay-active": overlayMenuActive,
            "layout-mobile-active": staticMenuMobileActive,
            "layout-static-inactive": staticMenuDesktopInactive && menuMode === "static",
            "p-input-filled": inputStyle === "filled",
            "p-ripple-disabled": !ripple,
        },
        colorScheme === "light" ? menuTheme : ""
    );

    let hayLogin = getTokenApi() && getUsernameApi();
    const history = useHistory();
    if (!hayLogin) {
        console.log("El usuario no está logeado");
        history.push("/login");
    }

    return (
        <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
            <div className="layout-content-wrapper">
                <AppTopBar
                    routers={routers}
                    topbarNotificationMenuActive={topbarNotificationMenuActive}
                    topbarUserMenuActive={topbarUserMenuActive}
                    onMenuButtonClick={onMenuButtonClick}
                    onSearchClick={toggleSearch}
                    onTopbarNotification={onTopbarNotificationMenuButtonClick}
                    onTopbarUserMenu={onTopbarUserMenuButtonClick}
                ></AppTopBar>

                <div className="layout-content">
                    {routers.map((router, index) => {
                        if (router.exact) {
                            return <Route key={`router${index}`} path={router.path} exact component={router.component} />;
                        }

                        return <Route key={`router${index}`} path={router.path} component={router.component} />;
                    })}
                </div>

                <AppFooter />
            </div>

            <AppMenu model={menu} menuMode={menuMode} active={menuActive} mobileMenuActive={staticMenuMobileActive} onMenuClick={onMenuClick} onMenuitemClick={onMenuitemClick} onRootMenuitemClick={onRootMenuitemClick}></AppMenu>

            <AppSearch searchActive={searchActive} onSearchClick={onSearchClick} onSearchHide={onSearchHide} />

            <div className="layout-mask modal-in"></div>
        </div>
    );
};

export default App;
