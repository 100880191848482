import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { estadosPedido } from "../api/varios";
import { getGeneralApi } from "../api/general";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";

export function DptoEstadoActualizador(props) {
    const { callBack, estado, dptoId, pedidoId } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const [datosDptos, setDatosDptos] = useState({});
    const [dptoDrop, setDptoDrop] = useState(dptoId);
    const [estadoDrop, setEstadoDrop] = useState(estado);
    const labelGeneral = "Act. Dpto./Estado";

    useEffect(() => {
        getGeneralApi("listaDptos")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosDptos(respuesta.data.items);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de departamentos" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            dpto: { id: dptoDrop }, //Hack para primeReact Dropdown
            estado: estadoDrop, //Hack para primeReact Dropdown
        };
        postGeneralApi("updateDatosPedidoDptoEstado", datosFormularioEnvio, pedidoId)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Datos guardados" });
                    overlayPanel.current.hide();
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error guardando datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-outlined" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="estado">Estado</label>
                                <Dropdown name="estado" value={estadoDrop} options={Array.from(estadosPedido)} onChange={(e) => setEstadoDrop(e.value)} placeholder="Seleccionar Estado" />
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="dpto">Departamento</label>
                                <Dropdown name="dpto" optionValue="id" optionLabel="nombre" value={dptoDrop} options={Array.from(datosDptos)} onChange={(e) => setDptoDrop(e.value)} placeholder="Seleccionar Departamento" />
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="dpto" style={{ visibility: "hidden" }}>
                                    Actualizar
                                </label>
                                <Button label="ACTUALIZAR" type="submit"></Button>
                            </div>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
