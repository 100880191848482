import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { clasificacion, lineaProyectoGeneral, lineaProyectoPlanos } from "../api/varios";
import { getGeneralApi, postGeneralApi } from "../api/general";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";
import { Fieldset } from "primereact/fieldset";

export function PedidoActualizador(props) {
    const { pedido, callBack } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const [datosTipologias, setDatosTipologias] = useState({});
    const [tipologiaDrop, setTipologiaDrop] = useState(pedido.tipologia.id);
    const [clasificacionDrop, setClasificacionDrop] = useState(pedido.clasificacion);
    const [lineaProyectoEstudioTecnicoDrop, setLineaProyectoEstudioTecnicoDrop] = useState(pedido.lineaProyectoEstudioTecnico);
    const [lineaProyectoPlanosDrop, setLineaProyectoPlanosDrop] = useState(pedido.lineaProyectoPlanos);
    const [lineaProyectoMaterialReferenciadoDrop, setLineaProyectoMaterialReferenciadoDrop] = useState(pedido.lineaProyectoMaterialReferenciado);
    const [lineaProyectoProveedorMaterialReferenciadoDrop, setLineaProyectoProveedorMaterialReferenciadoDrop] = useState(pedido.lineaProyectoProveedorMaterialReferenciado);
    const [datosRepresentantes, setDatosRepresentantes] = useState({});
    const [representanteDrop, setRepresentanteDrop] = useState(pedido.representante.id);
    const [datosGestorComercial, setDatosgestorcomercial] = useState({});
    const [gestorComercialDrop, setGestorComercialDrop] = useState(pedido.gestorComercial.id);
    const [fechaTeoricaCal, setFechaTeoricaCal] = useState(pedido.fechaTeorica);
    const [fechaEstimadaCal, setFechaEstimadaCal] = useState(pedido.fechaEstimada);
    const [fechaEntregaCal, setFechaEntregaCal] = useState(pedido.fechaEntrega);
    const labelGeneral = "Actualizar Datos";

    addLocale("es", CALENDAR_LOCALE_ES);

    async function getListaDatos() {
        await getGeneralApi("listaTipologias")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosTipologias(respuesta.data.items);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de tipologías" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
        await getGeneralApi("listaRepresentantes")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosRepresentantes(respuesta.data.items);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de representantes" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
        await getGeneralApi("listaGestoresComerciales")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosgestorcomercial(respuesta.data.items);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de gestores comerciales" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    useEffect(() => {
        getListaDatos();
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            clasificacion: clasificacionDrop, //Hack para primeReact Dropdown
            tipologia: { id: tipologiaDrop }, //Hack para primeReact Dropdown
            lineaProyectoEstudioTecnico: lineaProyectoEstudioTecnicoDrop, //Hack para primeReact Dropdown
            lineaProyectoPlanos: lineaProyectoPlanosDrop, //Hack para primeReact Dropdown
            lineaProyectoMaterialReferenciado: lineaProyectoMaterialReferenciadoDrop, //Hack para primeReact Dropdown
            lineaProyectoProveedorMaterialReferenciado: lineaProyectoProveedorMaterialReferenciadoDrop, //Hack para primeReact Dropdown
            representante: { id: representanteDrop },
            gestorComercial: { id: gestorComercialDrop },
            fechaTeorica: fechaTeoricaCal,
            fechaEstimada: fechaEstimadaCal,
            fechaEntrega: fechaEntregaCal,
        };

        postGeneralApi("updateDatosPedido", datosFormularioEnvio, pedido.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Datos guardados" });
                    overlayPanel.current.hide();
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error guardando datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-outlined" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="clasificacion">Clasificación</label>
                                <Dropdown name="clasificacion" value={clasificacionDrop} options={Array.from(clasificacion)} onChange={(e) => setClasificacionDrop(e.value)} placeholder="Seleccionar la clasificación" />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="tipologia">Tipología</label>
                                <Dropdown name="tipologia" optionValue="id" optionLabel="nombre" value={tipologiaDrop} options={Array.from(datosTipologias)} onChange={(e) => setTipologiaDrop(e.value)} placeholder="Seleccionar Tipologías" />
                            </div>
                            <Fieldset legend="Línea Proyecto" toggleable className="p-field p-col-12">
                                <div className="card p-fluid">
                                    <div className="p-formgrid p-grid">
                                        <div className="p-field p-col-6">
                                            <label htmlFor="lineaProyectoEstudioTecnico">Estudio Técnico</label>
                                            <Dropdown name="lineaProyectoEstudioTecnico" value={lineaProyectoEstudioTecnicoDrop} options={Array.from(lineaProyectoGeneral)} onChange={(e) => setLineaProyectoEstudioTecnicoDrop(e.value)} />
                                        </div>
                                        <div className="p-field p-col-6">
                                            <label htmlFor="lineaProyectoPlanos">Planos</label>
                                            <Dropdown name="lineaProyectoPlanos" value={lineaProyectoPlanosDrop} options={Array.from(lineaProyectoPlanos)} onChange={(e) => setLineaProyectoPlanosDrop(e.value)} />
                                        </div>
                                        <div className="p-field p-col-6">
                                            <label htmlFor="lineaProyectoMaterialReferenciado">Material Referenciado</label>
                                            <Dropdown name="lineaProyectoMaterialReferenciado" value={lineaProyectoMaterialReferenciadoDrop} options={Array.from(lineaProyectoGeneral)} onChange={(e) => setLineaProyectoMaterialReferenciadoDrop(e.value)} />
                                        </div>
                                        <div className="p-field p-col-6">
                                            <label htmlFor="lineaProyectoProveedorMaterialReferenciado">Envío Proveedor M/R</label>
                                            <Dropdown name="lineaProyectoProveedorMaterialReferenciado" value={lineaProyectoProveedorMaterialReferenciadoDrop} options={Array.from(lineaProyectoGeneral)} onChange={(e) => setLineaProyectoProveedorMaterialReferenciadoDrop(e.value)} />
                                        </div>
                                    </div>
                                </div>
                            </Fieldset>
                            <div className="p-field p-col-6">
                                <label htmlFor="representante">Representante</label>
                                <Dropdown name="representante" optionValue="id" optionLabel="nombre" value={representanteDrop} options={Array.from(datosRepresentantes)} onChange={(e) => setRepresentanteDrop(e.value)} placeholder="Seleccionar Representante" />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="gestorComercial">Gestor Comercial</label>
                                <Dropdown name="gestorComercial" optionValue="id" optionLabel="nombreCompleto" value={gestorComercialDrop} options={Array.from(datosGestorComercial)} onChange={(e) => setGestorComercialDrop(e.value)} placeholder="Seleccionar Gestor Comercial" />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="fechaTeorica">Fecha Teórica</label>
                                <Calendar showButtonBar={true} locale="es" dateFormat="dd/mm/yy" id="fechaTeorica" showIcon value={new Date(fechaTeoricaCal)} onChange={(e) => setFechaTeoricaCal(e.value)}></Calendar>
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="fechaEstimada">Fecha Estimada</label>
                                <Calendar showButtonBar={true} locale="es" dateFormat="dd/mm/yy" id="fechaEstimada" showIcon value={new Date(fechaEstimadaCal)} onChange={(e) => setFechaEstimadaCal(e.value)}></Calendar>
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="fechaEntrega">Fecha Entrega</label>
                                <Calendar showButtonBar={true} locale="es" dateFormat="dd/mm/yy" id="fechaEntrega" showIcon value={new Date(fechaEntregaCal)} onChange={(e) => setFechaEntregaCal(e.value)}></Calendar>
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="dpto" style={{ visibility: "hidden" }}>
                                    Actualizar
                                </label>
                                <Button label="ACTUALIZAR" type="submit"></Button>
                            </div>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
