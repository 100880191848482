import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { BotonIdTexto } from "../componentes/BotonIdTexto";

export const Clientes = () => {
    const [clientes, setClientes] = useState(null);
    const [selectedCustomers2, setSelectedCustomers2] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState("");
    const [loading2, setLoading2] = useState(true);
    const toast = useRef(null);

    useEffect(() => {
        getGeneralApi("listaUsuarios")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setClientes(respuesta.data.items);
                    setLoading2(false);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de clientes" });
                }
            })
            .catch((response) => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, []);

    const customer2TableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter2} onChange={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar en clientes" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const botonIdTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                <BotonIdTexto icono="user" ruta="/cliente/">
                    {data.id}
                </BotonIdTexto>
            </>
        );
    };

    const activoBody = (data, props) => {
        return <Checkbox checked={data[props.field]} disabled></Checkbox>;
    };

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <h5>Clientes</h5>
                        <p>Listado de clientes de Eurosegur</p>
                        <DataTable
                            value={clientes}
                            paginator
                            className="p-datatable-customers p-datatable-striped p-datatable-sm"
                            rows={20}
                            dataKey="id"
                            rowHover
                            selection={selectedCustomers2}
                            onSelectionChange={(e) => setSelectedCustomers2(e.value)}
                            globalFilter={globalFilter2}
                            emptyMessage="No se han encontrado clientes."
                            loading={loading2}
                            header={customer2TableHeader}
                        >
                            <Column headerStyle={{ width: "8rem", textAlign: "center" }} field="id" header="Id" sortable bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={botonIdTemplate}></Column>
                            <Column headerStyle={{ width: "4rem" }} field="activo" header="Act." sortable body={activoBody}></Column>
                            <Column headerStyle={{ width: "20rem" }} field="usuario" header="Username" sortable body={bodyTemplate}></Column>
                            <Column field="nombre" header="Nombre" sortable body={bodyTemplate}></Column>
                            <Column headerStyle={{ width: "5rem" }} field="eurosegur" header="Eurosegur" sortable body={activoBody}></Column>
                            <Column headerStyle={{ width: "5rem" }} field="leroy" header="Leroy" sortable body={activoBody}></Column>
                            <Column headerStyle={{ width: "5rem" }} field="saeblue" header="Saeblue" sortable body={activoBody}></Column>
                        </DataTable>
                        <div style={{ textAlign: "right" }}>
                            Total de clientes obtenidos: <strong>{clientes?.length}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
