import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import { getFechaCorta } from "../utiles/fechas";
import { useHistory } from "react-router-dom";
import { RolTexto } from "../componentes/RolTexto";
import { isAdminApi } from "../api/autenticacion";

export const Gestores = () => {
    const [gestores, setGestores] = useState(null);
    const [selectedCustomers1, setSelectedCustomers1] = useState(null);
    const [globalFilter1, setGlobalFilter1] = useState("");
    const [loading1, setLoading1] = useState(true);
    const toast = useRef();
    const history = useHistory();

    useEffect(() => {
        getGeneralApi("listaGestores")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setGestores(respuesta.data.items);
                    setLoading1(false);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de gestores" });
                }
            })
            .catch((response) => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, []);

    const customer1TableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter1} onChange={(e) => setGlobalFilter1(e.target.value)} placeholder="Buscar en gestores" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const rolTemplate = (data, props) => {
        return <RolTexto>{data[props.field]}</RolTexto>;
    };

    const dptoBodyTemplate = (data) => {
        return (
            <>
                <i className="pi pi-users"></i>
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }} className="image-text">
                    {data.dpto.nombre}
                </span>
            </>
        );
    };

    const activoBody = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <Checkbox checked={data[props.field]} disabled></Checkbox>
            </>
        );
    };
    const fechaBody = (data) => {
        return <>{getFechaCorta(data.fecha)}</>;
    };

    const botonTemplate = (data) => (
        <Button
            type="button"
            icon="pi pi-cog"
            className="p-button-secondary"
            onClick={(e) => {
                history.push("/gestor/" + data.id);
            }}
        ></Button>
    );

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <h5>Listado de Gestores</h5>
                        <p>Gestores que pueden acceder a la aplicación de EuroseGur PGI</p>
                        <DataTable
                            value={gestores}
                            paginator
                            className="p-datatable-customers p-datatable-striped p-datatable-sm"
                            rows={20}
                            dataKey="id"
                            rowHover
                            selection={selectedCustomers1}
                            onSelectionChange={(e) => setSelectedCustomers1(e.value)}
                            globalFilter={globalFilter1}
                            emptyMessage="No se han encontrado gestores"
                            loading={loading1}
                            header={customer1TableHeader}
                        >
                            <Column headerStyle={{ width: "4rem" }} field="id" header="Id" sortable body={bodyTemplate}></Column>
                            <Column headerStyle={{ width: "14rem" }} field="email" header="Username" sortable body={bodyTemplate}></Column>
                            <Column field="nombreCompleto" header="Nombre y Apellidos" sortable body={bodyTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="fecha" header="Alta" sortable body={fechaBody}></Column>
                            <Column headerStyle={{ width: "12rem" }} field="dpto.nombre" header="Dpto" sortable body={dptoBodyTemplate}></Column>
                            <Column headerStyle={{ width: "6rem" }} field="activo" header="Activo" sortable body={activoBody}></Column>
                            <Column headerStyle={{ width: "6rem" }} field="notificaciones" header="Notific." sortable body={activoBody}></Column>
                            <Column headerStyle={{ width: "14rem" }} field="emailNotificaciones" header="Email Notificaciones" sortable body={bodyTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} bodyStyle={{ textAlign: "center" }} field="rol" header="Rol" sortable body={rolTemplate}></Column>
                            {isAdminApi() && <Column headerStyle={{ width: "8rem", textAlign: "center" }} bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={botonTemplate}></Column>}
                        </DataTable>
                        <div style={{ textAlign: "right" }}>
                            Total de gestores obtenidos: <strong>{gestores?.length}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
