import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import { useParams, useHistory } from "react-router";
import { ActivoTexto } from "../componentes/ActivoTexto";

export const ClienteDatos = (props) => {
    const [datosCliente, setDatosCliente] = useState({});
    const toast = useRef();
    const { id } = useParams();
    const history = useHistory();

    const print = () => {
        window.print();
    };

    useEffect(() => {
        getGeneralApi("getDatosUsuario", id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosCliente(respuesta.data.items[0]);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, [id]);

    return (
        <div>
            <Toast ref={toast} />
            <Button type="button" label="Imprimir Ficha" icon="pi pi-print" onClick={print} style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }}></Button>

            <div className="p-grid">
                <div className="p-col">
                    <div className="card">
                        <div id="invoice-content">
                            <div className="invoice">
                                <h2>
                                    Cliente <b>nº {id}</b>
                                </h2>
                                <div className="invoice-to">
                                    <div className="bill-to"></div>
                                    <div className="invoice-to-info">
                                        <div>
                                            <ActivoTexto className="p-mr-1" activo={datosCliente?.activo} />
                                        </div>
                                        <div>
                                            Username: <b>{datosCliente?.usuario}</b>
                                        </div>
                                        <div>
                                            Nombre: <b>{datosCliente?.nombre}</b>
                                            {datosCliente?.nombre && " (" + datosCliente?.nombreComercial + ")"}
                                        </div>
                                        <div>
                                            NIF: <b>{datosCliente?.nif}</b>
                                            {datosCliente?.ref && (
                                                <>
                                                    {" - Ref:"} <b>{datosCliente?.ref}</b>
                                                </>
                                            )}
                                        </div>

                                        <div>
                                            Teléfono: <b>{datosCliente?.telefono}</b>
                                            {datosCliente?.movil && (
                                                <>
                                                    {" - Móvil: "} <b>{datosCliente?.movil}</b>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            Email: <b>{datosCliente?.email}</b>
                                            {datosCliente?.contacto && (
                                                <>
                                                    {" - Persona de contacto: "} <b>{datosCliente?.contacto}</b>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            Dirección: <b>{datosCliente?.direccion}</b>
                                            {datosCliente?.cp && (
                                                <>
                                                    {" · "}
                                                    <b>{datosCliente?.cp}</b>
                                                </>
                                            )}
                                            {datosCliente?.poblacion && (
                                                <>
                                                    {" · "}
                                                    <b>{datosCliente?.poblacion}</b>
                                                </>
                                            )}
                                            {datosCliente?.provincia && (
                                                <>
                                                    {" · "}
                                                    <b>({datosCliente?.cp})</b>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            Tipos de presupuesto:
                                            <br />
                                            <ActivoTexto className="p-mr-1" activo={datosCliente?.leroy} label="Leroy: " />
                                            <ActivoTexto className="p-mr-1" activo={datosCliente?.eurosegur} label="Eurosegur: " />
                                            <ActivoTexto className="p-mr-1" activo={datosCliente?.saeblue} label="Saeblue: " />
                                        </div>
                                    </div>
                                </div>
                                <div className="invoice-to">
                                    <div className="bill-to"></div>
                                    <div className="invoice-to-info">
                                        <div>
                                            Características:
                                            <br />
                                            <ActivoTexto className="p-mr-1" activo={datosCliente?.vistoBuenoMarchaPedido} label="Visto Bueno Marcha Pedido: " />
                                            <ActivoTexto className="p-mr-1" activo={datosCliente?.vistoBuenoSalidaPedido} label="Visto Bueno Salida Pedido: " />
                                            <ActivoTexto className="p-mr-1" activo={datosCliente?.lineaProyecto} label="Linea Proyecto: " />
                                            <ActivoTexto className="p-mr-1" activo={datosCliente?.top} label="Top: " />
                                            <ActivoTexto className="p-mr-1" activo={datosCliente?.datosDistribuidor} label="Datos Distribuidor: " />
                                        </div>
                                        <div>
                                            Representante: &nbsp;
                                            <strong>
                                                <i className="pi pi-user-minus p-mr-2"></i>
                                                {datosCliente?.representante?.nombre}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "20px 0 0 20px" }} className="p-field">
                            <Button label="Volver" icon="pi pi-angle-double-left" type="button" className="p-button-secondary" onClick={(e) => history.goBack()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
