import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";

export const Tipologias = () => {
    const [tipologias, setTipologias] = useState({});
    const [loading, setLoading] = useState(true);
    const toast = useRef();

    useEffect(() => {
        getGeneralApi("listaTipologias")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setTipologias(respuesta.data.items);
                    setLoading(false);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de tipologías." });
                }
            })
            .catch((response) => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, []);

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <h5>Listado de Tipologías</h5>
                        <p>Tipologías de pedido en la aplicación de EuroseGur PGI</p>
                        <DataTable value={tipologias} className="p-datatable-customers p-datatable-striped p-datatable-sm" dataKey="id" rowHover emptyMessage="No se han encontrado gestores" loading={loading}>
                            <Column headerStyle={{ width: "6em" }} body={(data, props) => props.rowIndex + 1}></Column>
                            <Column field="id" headerStyle={{ width: "6em" }} header="Id" sortable body={bodyTemplate}></Column>
                            <Column field="nombre" headerStyle={{ width: "10em" }} header="Nombre" sortable body={bodyTemplate}></Column>
                            <Column field="dias" headerStyle={{ width: "10em" }} header="Días" sortable body={bodyTemplate}></Column>
                            <Column field="descripcion" header="Descripción" sortable body={bodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};
