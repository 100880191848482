export const estadosPresupuesto = [
    { label: "NORMAL", value: "1" },
    { label: "A PEDIDO", value: "2" },
];

export const estadosPresupuestoMasTodos = [{ label: "TODOS", value: "0" }, ...estadosPresupuesto];

export const estadosPedido = [
    { label: "CREADO", value: "CREADO" },
    { label: "RECIBIDO", value: "RECIBIDO" },
    { label: "PRECONFIRMADO", value: "PRECONFIRMADO" },
    { label: "FABRICACION", value: "FABRICACION" },
    { label: "TERMINADO", value: "TERMINADO" },
    { label: "ENVIADO", value: "ENVIADO" },
    { label: "PARALIZADO", value: "PARALIZADO" },
    { label: "CANCELADO", value: "CANCELADO" },
];

export const estadosPedidoMasTodos = [{ label: "TODOS", value: "TODOS" }, ...estadosPedido];

export const clasificacion = [
    { label: "NO CLASIF", value: 0 },
    { label: "STOCK", value: 1 },
    { label: "REF. LEROY", value: 2 },
    { label: "BASICA", value: 3 },
    { label: "LINEA PROY.", value: 4 },
    { label: "PUERTA + INST.", value: 5 },
    { label: "REPUESTO", value: 6 },
    { label: "SERVICIO MED.", value: 7 },
];

export const lineaProyectoGeneral = [
    { label: "NO DEFINIDO", value: 0 },
    { label: "NO", value: 1 },
    { label: "SI", value: 2 },
];

export const lineaProyectoPlanos = [
    { label: "NO DEFINIDO", value: 0 },
    { label: "ENVIO A GESTOR", value: 1 },
    { label: "ENVIO A CLIENTE", value: 2 },
    { label: "RECIBIDO DE CLIENTE", value: 3 },
];

export const incidenciaEstado = [
    { label: "Abierta", value: 0 },
    { label: "Llamado al cliente para comunicar Incidencia", value: 1 },
    { label: "Introducida nueva fecha estimada de fabricación", value: 2 },
    { label: "Llamado al cliente para comunicar nueva fecha prevista de salida", value: 3 },
    { label: "Reconfirmado si se trata de pedido completo o Pedido parcial", value: 4 },
    { label: "Registrada y cerrada NNCC", value: 5 },
    { label: "Cerrada por calidad", value: 6 },
];
