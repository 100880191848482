import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";
import { Checkbox } from "primereact/checkbox";

export function AvisoActualizador(props) {
    const { callBack, aviso } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();

    const [activoChecked, setActivoChecked] = useState(aviso.activo);
    const labelGeneral = "Actualizar Aviso";

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            aviso: e.target.aviso.value,
            activo: activoChecked, //Hack para primeReact Checkbox
        };
        postGeneralApi("updateDatosAviso", datosFormularioEnvio, aviso.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Datos guardados" });
                    overlayPanel.current.hide();
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error guardando datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-outlined" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <div className="p-formgrid p-grid">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="activo" name="activo" checked={activoChecked} onChange={(evento) => setActivoChecked(evento.checked)} />
                                <label htmlFor="activo">Pendiente</label>
                            </div>
                            <div className="p-field">
                                <label htmlFor="aviso">Texto del Aviso</label>
                                <InputTextarea cols="40" name="aviso" rows="4" defaultValue={aviso.aviso} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="actualizar" style={{ visibility: "hidden" }}>
                                    Actualizar
                                </label>
                                <Button label="ACTUALIZAR" type="submit"></Button>
                            </div>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
