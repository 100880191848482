import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import { EstadoTexto } from "../componentes/EstadoTexto.js";
import { Checkbox } from "primereact/checkbox";

export const Flujo = () => {
    const [flujos, setFlujos] = useState({});
    const [loading1, setLoading1] = useState(true);
    const toast = useRef();
    const [expandedRows, setExpandedRows] = useState([]);

    useEffect(() => {
        getGeneralApi("listaFlujo")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    //console.log("/>>: " + JSON.stringify(respuesta.data.items));
                    setFlujos(respuesta.data.items);
                    setLoading1(false);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de flujos de Trabajo" });
                }
            })
            .catch((response) => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, []);

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const dptoBodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <i className="pi pi-users"></i>
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }} className="image-text">
                    {data.dpto.nombre}
                </span>
            </>
        );
    };

    const statusBodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <EstadoTexto estado={data?.estado} />
            </>
        );
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Destinos</h5>
                <DataTable value={data?.flujoDestino} className="p-datatable-customerss" emptyMessage="No se hay flujos de destino">
                    <Column field="id" header="Id" sortable body={bodyTemplate}></Column>
                    <Column field="ref" header="Ref" sortable body={bodyTemplate}></Column>
                    <Column field="nombre" header="Nombre" sortable body={bodyTemplate}></Column>
                </DataTable>
            </div>
        );
    };

    const activoBody = (data, props) => {
        return <Checkbox checked={data[props.field]} disabled></Checkbox>;
    };

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <h5>Listado de Flujo de Trabajo</h5>
                        <p>Dpartamentos y estados posibles de los pedidos en la aplicación de EuroseGur PGI</p>
                        <DataTable
                            value={flujos}
                            className="p-datatable-customers p-datatable-striped p-datatable-sm"
                            dataKey="id"
                            rowHover
                            emptyMessage="No se han encontrado flujos de trabajo"
                            loading={loading1}
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            rowExpansionTemplate={rowExpansionTemplate}
                        >
                            <Column expander headerStyle={{ width: "4em" }} />
                            {/*<Column headerStyle={{ width: "4em" }} body={(data, props) => props.rowIndex + 1}></Column>*/}
                            <Column field="id" header="Id" headerStyle={{ width: "4em" }} sortable body={bodyTemplate}></Column>
                            <Column field="orden" header="Orden" headerStyle={{ width: "4em" }} sortable body={bodyTemplate}></Column>
                            <Column field="manual" headerStyle={{ width: "4rem" }} header="Manual" sortable body={activoBody}></Column>
                            <Column field="dpto.nombre" headerStyle={{ width: "12em" }} header="Dpto." sortable body={dptoBodyTemplate}></Column>
                            <Column headerStyle={{ width: "12rem" }} field="estado" header="Estado" sortable body={statusBodyTemplate}></Column>
                            <Column field="ref" headerStyle={{ width: "12em" }} header="Ref" sortable body={bodyTemplate}></Column>
                            <Column field="nombre" headerStyle={{ width: "12em" }} header="Nombre" sortable body={bodyTemplate}></Column>
                            <Column field="descripcion" header="Descripción" sortable body={bodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};
