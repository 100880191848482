import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import Moment from "react-moment";
import { useParams, useHistory } from "react-router";
import { EstadoTexto } from "../componentes/EstadoTexto";
import { TrazaTexto } from "../componentes/TrazaTexto";
import { AvisoTexto } from "../componentes/AvisoTexto";
import { size, map } from "lodash";
import { DptoEstadoActualizador } from "../componentes/DptoEstadoActualizador";
import { PedidoActualizador } from "../componentes/PedidoActualizador";
import { AvisoNuevo } from "../componentes/AvisoNuevo";
import { CancelarPedido } from "../componentes/CancelarPedido";
import { TriestadoTexto } from "../componentes/TriestadoTexto";
import { AvisoActualizador } from "../componentes/AvisoActualizador";
import { ClasificacionTexto } from "../componentes/ClasificacionTexto";
import { TabView, TabPanel } from "primereact/tabview";
import { Fieldset } from "primereact/fieldset";
import { isAdminApi } from "../api/autenticacion";
import { FlujoActualizador } from "../componentes/FlujoActualizador";
import { lineaProyectoPlanos } from "../api/varios";
import { SelectorProcesos } from "../componentes/SelectorProcesos";
import { IncidenciaNueva } from "../componentes/IncidenciaNueva";
import { IncidenciaTexto } from "../componentes/IncidenciaTexto";
import { IncidenciaActualizador } from "../componentes/IncidenciaActualizador";
import { PptoActualizador } from "../componentes/PptoActualizador";

export const PedidoDatos = (props) => {
    const [datosPedido, setDatosPedido] = useState();
    const toast = useRef();
    const { id } = useParams();
    const history = useHistory();
    const [reload, setReload] = useState(Date.now());
    const [actEstado, setActEstado] = useState();
    const [actDpto, setActDpto] = useState();

    const print = () => {
        window.print();
    };

    function totalAvisoPendientes() {
        let totalAvisoActivos = 0;
        datosPedido?.avisos.forEach((aviso) => {
            if (aviso.activo) {
                totalAvisoActivos++;
            }
        });
        return totalAvisoActivos;
    }

    function totalIncidenciasNoCerradas() {
        let totalIncidenciasNoCerradas = 0;
        datosPedido?.incidencias.forEach((incidencia) => {
            if (!incidencia.cerrada) {
                totalIncidenciasNoCerradas++;
            }
        });
        return totalIncidenciasNoCerradas;
    }

    async function getDatosPedido(idParam) {
        await getGeneralApi("getDatosPedido", idParam)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    const datosPedidoEntrada = respuesta.data.items[0];
                    //console.log("/>>: " + JSON.stringify(datosPedidoEntrada));
                    setDatosPedido(datosPedidoEntrada);
                    setActEstado(datosPedidoEntrada?.estado);
                    setActDpto(datosPedidoEntrada?.dpto?.id);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo datos" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
        //console.log("/>> 2 actFechaTeorica: " + actFechaTeorica);
    }

    useEffect(() => {
        getDatosPedido(id);
    }, [id, reload]);

    return (
        <div>
            <Toast ref={toast} />
            <Button type="button" label="Imprimir Ficha" icon="pi pi-print" onClick={print} style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }}></Button>

            <div className="p-grid">
                <div className="p-col">
                    <div className="card">
                        <div id="invoice-content">
                            <div className="invoice">
                                <div className="p-grid">
                                    <div className="p-md-6">
                                        <h2>
                                            Pedido <b>nº {id}</b>
                                        </h2>
                                    </div>
                                    <div className="p-md-6">
                                        <div className="p-grid p-dir-rev">
                                            {isAdminApi() && (
                                                <>
                                                    <div className="p-md-3 p-sm-6">{actDpto && <DptoEstadoActualizador pedidoId={id} callBack={setReload} estado={actEstado} dptoId={actDpto} />}</div>
                                                    <div className="p-md-3 p-sm-6">{datosPedido && <PedidoActualizador pedido={datosPedido} callBack={setReload} />}</div>
                                                    <div className="p-md-3 p-sm-6">{datosPedido && <FlujoActualizador pedido={datosPedido} callBack={setReload} />}</div>
                                                </>
                                            )}
                                            <div className="p-md-3 p-sm-6">{datosPedido && datosPedido.estado !== "CANCELADO" && <CancelarPedido pedidoId={id} callBack={setReload} estado={actEstado} />}</div>
                                            <div className="p-md-3 p-sm-6">
                                                <AvisoNuevo pedidoId={id} callBack={setReload} estado={actEstado} />{" "}
                                            </div>
                                            <div className="p-md-3 p-sm-6"> {datosPedido && <PptoActualizador pedido={datosPedido} callBack={setReload} />} </div>
                                            <div className="p-md-3 p-sm-6">
                                                <IncidenciaNueva pedidoId={id} callBack={setReload} estado={actEstado} />
                                            </div>
                                            {datosPedido && datosPedido.estado !== "CANCELADO" && <SelectorProcesos param={datosPedido?.flujo?.ref} pedido={datosPedido} callBack={setReload} />}
                                        </div>
                                    </div>
                                </div>
                                <TabView>
                                    <TabPanel header="Principal&nbsp;" rightIcon="pi pi-info-circle">
                                        <div className="invoice-to">
                                            <div className="bill-to p-mb-6">Datos Generales</div>
                                            <div className="invoice-to-info">
                                                <div>
                                                    Estado:{" "}
                                                    <span>
                                                        <EstadoTexto estado={datosPedido?.estado} />{" "}
                                                    </span>
                                                </div>
                                                {datosPedido?.estado === "CANCELADO" && (
                                                    <div>
                                                        <Fieldset legend="Datos Cancelación" toggleable>
                                                            <p>
                                                                Cantidad valoración: <strong>{datosPedido?.canceladoCantidad} €</strong>
                                                            </p>
                                                            <p>
                                                                Comentario: <strong>{datosPedido?.canceladoComentario}</strong>
                                                            </p>
                                                        </Fieldset>
                                                    </div>
                                                )}
                                                <div>
                                                    Dpto: &nbsp;
                                                    <strong>
                                                        <i style={{ padding: "0 10px 0 1px" }} className="pi pi-users"></i>
                                                        {datosPedido?.dpto?.nombre}
                                                    </strong>
                                                </div>
                                                <div>
                                                    Flujo: &nbsp;
                                                    <i style={{ padding: "0 10px 0 1px" }} className="pi pi-sitemap"></i>
                                                    <strong>{datosPedido?.flujo?.nombre} </strong>[{datosPedido?.flujo?.ref}]
                                                </div>
                                                <div>
                                                    Fecha:&nbsp;
                                                    <strong>
                                                        <Moment format="DD/MM/YYYY">{datosPedido?.fecha}</Moment>
                                                    </strong>
                                                </div>
                                                <div>
                                                    Fecha Teórica:&nbsp;
                                                    <strong>
                                                        <strong>{datosPedido?.fechaTeorica ? <Moment format="DD/MM/YYYY">{datosPedido?.fechaTeorica}</Moment> : "No definida"}</strong>
                                                    </strong>
                                                </div>
                                                <div>
                                                    Fecha Estimada:&nbsp;
                                                    <strong>{datosPedido?.fechaEstimada ? <Moment format="DD/MM/YYYY">{datosPedido?.fechaEstimada}</Moment> : "No definida"}</strong>
                                                </div>
                                                <div>
                                                    Fecha Entrega:&nbsp;
                                                    <strong>{datosPedido?.fechaEntrega ? <Moment format="DD/MM/YYYY">{datosPedido?.fechaEntrega}</Moment> : "No definida"}</strong>
                                                </div>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        className="p-button-secondary"
                                                        onClick={(e) => {
                                                            history.push("/cliente/" + datosPedido?.ppto?.usuario?.id);
                                                        }}
                                                    >
                                                        <i className="p-pr-2 pi pi-user"></i>
                                                        Cliente:&nbsp; <b>{datosPedido?.ppto?.usuario?.nombre}</b>
                                                    </Button>
                                                </div>
                                                <div>
                                                    Referencia: &nbsp;
                                                    <strong>{datosPedido?.ref}</strong>
                                                </div>
                                                <div>
                                                    Comentario: &nbsp;
                                                    <strong>{datosPedido?.comentario}</strong>
                                                </div>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        className="p-button-secondary"
                                                        onClick={(e) => {
                                                            history.push("/presupuesto/" + datosPedido?.ppto?.id);
                                                        }}
                                                    >
                                                        <i className="p-pr-2 pi pi-ticket"></i> Presupuesto nº&nbsp;<b>{datosPedido?.ppto?.id}</b>
                                                    </Button>
                                                </div>
                                                <div>
                                                    Gestor Comercial: &nbsp;
                                                    <strong>
                                                        <i className="pi pi-user-plus p-mr-2"></i>
                                                        {datosPedido?.gestorComercial?.nombreCompleto}
                                                    </strong>
                                                </div>
                                                <div>
                                                    Representante: &nbsp;
                                                    <strong>
                                                        <i className="pi pi-user-minus p-mr-2"></i>
                                                        {datosPedido?.representante?.nombre}
                                                    </strong>
                                                </div>
                                                <div>
                                                    Clasificación: <span>{datosPedido?.clasificacion && <ClasificacionTexto id={datosPedido?.clasificacion} />}</span>
                                                </div>
                                                <div>
                                                    Tipología: &nbsp;
                                                    <strong>
                                                        {datosPedido?.tipologia?.nombre} ({datosPedido?.tipologia?.descripcion})
                                                    </strong>
                                                </div>
                                                <div>
                                                    <Fieldset legend="Línea Proyecto" toggleable>
                                                        <TriestadoTexto className="p-mr-1" valor={datosPedido?.lineaProyectoEstudioTecnico} label="Estudio Técnico: " />
                                                        <TriestadoTexto
                                                            className="p-mr-1"
                                                            valor={datosPedido?.lineaProyectoPlanos}
                                                            label="Planos: "
                                                            label0={lineaProyectoPlanos[0]?.label}
                                                            label1={lineaProyectoPlanos[1]?.label}
                                                            label2={lineaProyectoPlanos[2]?.label}
                                                            label3={lineaProyectoPlanos[3]?.label}
                                                        />
                                                        <TriestadoTexto className="p-mr-1" valor={datosPedido?.lineaProyectoMaterialReferenciado} label="Material Referenciado: " />
                                                        <TriestadoTexto className="p-mr-1" valor={datosPedido?.lineaProyectoProveedorMaterialReferenciado} label="Envío a Proveedor M/R: " />
                                                        {size(datosPedido?.llegadaMaterial) > 0 && (
                                                            <div>
                                                                <h5 className="p-mt-3">Fechas Estimadas Llegada de Material:</h5>
                                                                <ul>
                                                                    {map(datosPedido?.llegadaMaterial, (lm, index) => (
                                                                        <li>
                                                                            <strong>{lm?.fecha ? <Moment format="DD/MM/YYYY">{lm?.fecha}</Moment> : "No definida"}</strong>: {lm?.proveedor}, {lm?.material}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </Fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel header={`Avisos (${totalAvisoPendientes()} Pend.) `} rightIcon="pi pi-exclamation-triangle">
                                        {datosPedido?.avisos && datosPedido.avisos.length > 0 ? (
                                            <div className="invoice-to">
                                                <div className="bill-to">Avisos</div>
                                                {map(datosPedido?.avisos, (aviso, index) => (
                                                    <div key={index}>
                                                        <div className="p-grid p-dir-rev" style={{ margin: "10px 0 -70px 0" }}>
                                                            <div className="p-col-2">
                                                                <AvisoActualizador callBack={setReload} aviso={aviso} />
                                                            </div>
                                                        </div>
                                                        <AvisoTexto aviso={aviso}></AvisoTexto>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="p-mt-6">No existen avisos aún.</div>
                                        )}
                                    </TabPanel>

                                    <TabPanel header={`Incidencias (${totalIncidenciasNoCerradas()} Abiertas) `} rightIcon="pi pi-exclamation-circle">
                                        {datosPedido?.incidencias && datosPedido?.incidencias.length > 0 ? (
                                            <div className="invoice-to">
                                                <div className="bill-to">Incidencias</div>
                                                {map(datosPedido?.incidencias, (incidencia, index) => (
                                                    <div key={index}>
                                                        <div className="p-grid p-dir-rev" style={{ margin: "10px 0 -70px 0" }}>
                                                            <div className="p-col-2">
                                                                <IncidenciaActualizador callBack={setReload} incidencia={incidencia} />
                                                            </div>
                                                        </div>
                                                        <IncidenciaTexto incidencia={incidencia}></IncidenciaTexto>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="p-mt-6">No existen incidencias aún.</div>
                                        )}
                                    </TabPanel>

                                    <TabPanel header="Trazas&nbsp;" rightIcon="pi pi-ellipsis-h">
                                        {datosPedido?.trazas && datosPedido.trazas.length > 0 ? (
                                            <div className="invoice-to">
                                                <div className="bill-to">Traza</div>
                                                <ul>
                                                    {map(datosPedido?.trazas, (traza, index) => (
                                                        <li key={index} style={{ margin: "10px" }}>
                                                            {index + 1}) <TrazaTexto traza={traza} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            <div className="p-mt-6">No existen trazas aún.</div>
                                        )}
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                        <div style={{ padding: "20px 0 0 20px" }} className="p-field">
                            <Button label="Volver" icon="pi pi-angle-double-left" type="button" className="p-button-secondary" onClick={(e) => history.goBack()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
